import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { CreateNoticeComponentInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { GroupOfUserInterface, NoticeInterface } from "../../../Interfaces/Entities";
import { FormatDate } from "../../../Helpers/DateHelpers";
import { CheckIfNoticeIsComplete } from "../../../Helpers/CheckHelpers";
import { FetchPostFunction } from "../../../Helpers/FetchHelpers";


function CreateNoticeComponent( { updateContext } : CreateNoticeComponentInterface) {

	const { schoolOfUser, groupsOfUser, user } = useGeneralContext()
	const [isNoticeSolved, setIsNoticeSolved] = useState<boolean>(false)
	const [currentGroup, setCurrentGroup] = useState<string>('')
	const [selectedGroup, setSelectedGroup] = useState<GroupOfUserInterface | undefined>(undefined)
	const [currentLocation, setCurrentLocation] = useState<string>('')
	const [currentType, setCurrentType] = useState<string>('')
	const [currentDescription, setCurrentDescription] = useState<string>('')

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsNoticeSolved(e.target.checked);
	};

	const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const groupId = e.target.value;
		setCurrentGroup(groupId);
		const group = groupsOfUser.find((group) => group.group_id === groupId);
		setSelectedGroup(group);
	};


	const handleSubmit = async () => {
		if (!user || !schoolOfUser) {
			return
		}
		if (CheckIfNoticeIsComplete(currentGroup, currentType, currentDescription, selectedGroup)) {
			if (!selectedGroup) {
				return
			}
			try {
				const url = `${process.env.REACT_APP_BACKEND_URL}/news?` +
					`user_id=${user.SK}&school_id=${schoolOfUser.SK}`
				const attrs = {
					groups: [{ name: selectedGroup.label, id: selectedGroup.group_id }],
					type: currentType,
					location: currentLocation,
					description: currentDescription,
					notify: true,
					user: user.name,
					position: user.position,
					solved: isNoticeSolved
				};
				await FetchPostFunction(url, { 'attrs': attrs });
				const newNotice  : NoticeInterface = { 
					type: currentType,
					location: currentLocation,
					description: currentDescription, 
					user_id: user.SK,
					position: user.position,
					solved: isNoticeSolved,
					groups: [{ name: selectedGroup.label, id: selectedGroup.group_id }],
					notify: true,
					comments : [],
					images: [],
					date: FormatDate(new Date()),
					SK: FormatDate(new Date()),
					title : undefined
				}
				updateContext(newNotice)
				alert('Noticia correctamenete creada')
			} catch (error) {
				alert('Hubo un problema al crear la noticia')
			}
		}
	}

	return (
		<div className="CreateNoticeMainDiv">
			<div className="CreateNoticeSubMainDiv">
				<h4>Selecciona la situación crítica</h4>
				<select
					value={currentType}
					onChange={(e) => setCurrentType(e.target.value)}
					className="CreateNoticeSelect"
				>
					<option value="" disabled>{'Selecciona una emergencia'}</option>
					{!schoolOfUser ? <></> : schoolOfUser.types.map((type: string) => {
						if (type !== 'General') {
							return <option value={type}>{type}</option>;
						}
						return null;
					})}
				</select>
			</div>
			<div className="CreateNoticeSubMainDiv">
				<h4>Selecciona el grupo a quien va dirigido</h4>
				<select
					value={currentGroup}
					onChange={handleGroupChange}
					className="CreateNoticeSelect"
				>
					<option value="" disabled>{'Selecciona un grupo'}</option>
					{groupsOfUser.map((group: GroupOfUserInterface) => (
						<option value={group.group_id}>{group.label}</option>
					))}
				</select>
			</div>
			<div className="CreateNoticeSubMainDiv SmallTextArea">
				<h4>Indica tu ubicación</h4>
				<textarea
					value={currentLocation}
					onChange={(e) => setCurrentLocation(e.target.value)}
				/>
			</div>
			<div className="CreateNoticeSubMainDiv BigTextArea">
				<h4>Escribe tu mensaje</h4>
				<textarea
					value={currentDescription}
					onChange={(e) => setCurrentDescription(e.target.value)}
				/>
			</div>
			<div className="CreateNoticeSubMainDiv">
				<label>
					<input
						type="checkbox"
						checked={isNoticeSolved}
						onChange={handleCheckboxChange}
					/>
					El aviso ya esta resuelto
				</label>
			</div>
			<div className="CreateNoticeSubMainDiv">
				<button type='button' onClick={handleSubmit}>
					Crear Aviso
				</button>
			</div>
		</div>
	)
}

export default CreateNoticeComponent