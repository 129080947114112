import { ReactNode, useContext, useEffect, useState } from "react";
import { useGeneralContext } from "../GeneralContext/GeneralProvider";
import SchoolAdminModalContext from "./SchoolAdminContext";
import { GroupInterface, SchoolInterface, StudentInterface, UserInterface } from "../../Interfaces/Entities";
import { FetchGetFunction } from "../../Helpers/FetchHelpers";

function SchoolAdminModalProvider({ children }: { children: ReactNode }) {

	const { schoolOfUser } = useGeneralContext()
	const [ schoolGroups, setSchoolGroups ] = useState<GroupInterface[]>([]);
	const [ schoolUsers, setSchoolUsers ] = useState<UserInterface[]>([])
	const [ schoolStudents, setSchoolStudents ] = useState<StudentInterface[]>([])
	const [ savedGroups, setSavedGroups ] = useState<string[]>([])
	const [ userToEdit, setUserToEdit ] = useState<UserInterface | undefined>(undefined)
	const [ studentToEdit, setStudentToEdit ] = useState<StudentInterface | undefined>(undefined)


	const fetchData = async (urlBase: string, schoolId: string, setter: (data: any) => void) => {
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/${urlBase}?school_id=${schoolId}`;
			const dataToSet = await FetchGetFunction(url)
			setter(dataToSet);
		} catch (error) {}
	};
	
	const getInfoOfSingularGroup = async (groupSk: string): Promise<GroupInterface> => {
		if (!schoolOfUser) {
			return {} as Promise<GroupInterface>
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${groupSk}?school_id=${schoolOfUser.SK}`;
			const singularGroup = await FetchGetFunction(url)
			const updatedGroups = schoolGroups.map((group: GroupInterface) => (
				group.SK === groupSk ? singularGroup : group
			));
			setSchoolGroups(updatedGroups);
			return singularGroup;
		} catch (error) {}
	};
	
	const getGroupsFromBackEnd = async () => {
		if (!schoolOfUser) {
			return
		}
		await fetchData('groups', schoolOfUser.SK, setSchoolGroups);
	};
	
	const getGroupsFromBackEndForSpecificSchool = async (school: SchoolInterface) => {
		await fetchData('groups', school.SK, setSchoolGroups);
	};
	
	const getAllUsersOfSchool = async () => {
		if (!schoolOfUser) {
			return
		}
		await fetchData('users', schoolOfUser.SK, setSchoolUsers);
	};
	
	const getAllUsersOfSchoolForSpecificSchool = async (school: SchoolInterface) => {
		await fetchData('users', school.SK, setSchoolUsers);
	};
	
	const getAllStudentsOfSchool = async () => {
		if (!schoolOfUser) {
			return
		}
		await fetchData('students', schoolOfUser.SK, setSchoolStudents);
	};
	
	const getAllStudentsOfSchoolForSpecificSchool = async (school: SchoolInterface) => {
		await fetchData('students', school.SK, setSchoolStudents);
	};
	
	useEffect(() => {
		getAllStudentsOfSchool();
		getAllUsersOfSchool();
		getGroupsFromBackEnd();
	}, []);
	
	const updateBasicFunctions = async (school: SchoolInterface) => {
		await getAllStudentsOfSchoolForSpecificSchool(school);
		await getAllUsersOfSchoolForSpecificSchool(school);
		await getGroupsFromBackEndForSpecificSchool(school);
	};

	return (
		<SchoolAdminModalContext.Provider value={{
			setSchoolUsers, schoolGroups, setSchoolGroups, getGroupsFromBackEnd, schoolUsers, getAllUsersOfSchool, 
			schoolStudents, setSchoolStudents, getAllStudentsOfSchool, savedGroups, setSavedGroups, getInfoOfSingularGroup,
			userToEdit, setUserToEdit, studentToEdit, setStudentToEdit, updateBasicFunctions
		}}>
			{children}
		</SchoolAdminModalContext.Provider>
	);
}

function useSchoolAdminModalContext() {
	const context = useContext(SchoolAdminModalContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context;
}

export { useSchoolAdminModalContext }
export default SchoolAdminModalProvider;