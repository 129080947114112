import { useEffect, useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { RoleInterface } from "../../../Interfaces/Entities"
import ViewSingularTemplate from "../ViewData/ViewSingularTemplate"
import { FetchPutFunction } from "../../../Helpers/FetchHelpers"
import '../SchoolAdmin.css'


function MapTemplates() {

	const { schoolOfUser, setSchoolOfUser } = useGeneralContext()
	const [currentTemplates, setCurrentTemplates] =	useState<{ [templateName: string]: RoleInterface }>(schoolOfUser ? 
		schoolOfUser.templates ? schoolOfUser.templates : {} : {})
	const [currentTemplatesKeys, setCurrentTemplatesKeys] = useState<string[]>(schoolOfUser ? schoolOfUser.templates ? 
		Object.keys(schoolOfUser.templates) : [] : [])
	const [hasMounted, setHasMounted] = useState<boolean>(false)
	

	const handleNewTemplate = () => {
		setCurrentTemplatesKeys([...currentTemplatesKeys, 'Plantilla de ejemplo'])
		setCurrentTemplates({ ...currentTemplates, 'Plantilla de ejemplo': { 'General': 'mid' } });
	}

	const updateSchoolTemplates = async () => {
		if (!schoolOfUser) {
			return
		}
		try {
			setCurrentTemplatesKeys(Object.keys(currentTemplates))
			setCurrentTemplates(currentTemplates)
			const url = `${process.env.REACT_APP_BACKEND_URL}/schools/${schoolOfUser.SK}`;
			const body = {
				"attrs": {
					"templates": currentTemplates,
				}
			};
			await FetchPutFunction(url, body)
			const updatedSchool = { ...schoolOfUser, templates: currentTemplates }
			setSchoolOfUser(updatedSchool)
		} catch { }
	}

	const updateEmergenciesIfNeeded = async () => {
		if (hasMounted && !currentTemplatesKeys.includes("Plantilla de ejemplo")) {
			await updateSchoolTemplates();
		} else {
			setHasMounted(true)
		}
	};

	useEffect(() => {
		updateEmergenciesIfNeeded();
	}, [currentTemplates])

	return (
		<div className="MapTemplateMainDiv">
			{currentTemplatesKeys.map((templateCode, index) => {
				if (currentTemplates[templateCode]) {
					return (
						<ViewSingularTemplate
							templateCode={templateCode}
							index={index}
							templates={currentTemplates}
							setTemplates={setCurrentTemplates}
						/>
					)
				}
			})}
			<button type='button' className="CreateTemplateButton" onClick={handleNewTemplate}>
				Crear nuevo
			</button>
		</div>
	)
}

export default MapTemplates