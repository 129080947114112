import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { SummaryMyGroupInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { TranslateClassnameForButton, TranslatePriorityLabel } from "../../../Helpers/TranslateHelpers"
import '../SharedComponents.css'


function SummaryMyGroup({ groupToShow }: SummaryMyGroupInterface) {

	const { schoolOfUser } = useGeneralContext()
	const { setIdOfGroupToSee } = useUserModalContext()
	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)

	const handleGroupRedirect = () => {
		setIdOfGroupToSee(groupToShow.group_id)
	}

	return (
		<div className="SummaryMyGroupMainDiv" onClick={() => setIsBoxOpen(!isBoxOpen)}>
			<div className="SummaryMyGroupSubDiv">
				<div className="SummaryMyGroupInternalRowDiv">
					<div className="SummaryMyGroupInternalLeftDiv">
						<h2>{groupToShow.label}</h2>
					</div>
					<div className="SummaryMyGroupInternalRightDiv">
						<button type='button' onClick={handleGroupRedirect}> 
							Ir al grupo 
						</button>
					</div>
				</div>
				{isBoxOpen && (
					<div className="">
						{!schoolOfUser ? <></> : schoolOfUser.types.map((type: string) => {
							if (type !== 'General') {
								return (
									<div className="SummaryMyGroupInternalRowDiv SuperiorLine">
										<div className={`SummaryMyGroupInternalLeftDiv 
											${TranslateClassnameForButton(type, schoolOfUser)}`}
										>
											<h4>{type}</h4>
										</div>
										<div className="SummaryMyGroupInternalRightDiv">
											<h4>
												{type in groupToShow.priorities ? 
													TranslatePriorityLabel(groupToShow.priorities[type]) : 'Baja'}
											</h4>
										</div>
									</div>
								)
							}
						})}
					</div>
				)}
			</div>
		</div>
	)
}

export default SummaryMyGroup