import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import { useAdminModalContext } from "../../Contexts/AdminModalContext/AdminModalProvider";
import { SchoolInterface } from "../../Interfaces/Entities";
import { DefaultSchool } from "../../Interfaces/DefaultEntities";
import EditSchool from "../../Components/AdminModalComponents/EditData/EditSchool";
import NavBar from "../../Others/NavBar/NavBar";
import SummarySuperiorSchool from "../../Components/SharedComponents/SummaryData/SummarySuperiorSchool";
import UserHomeModal from "../UserModals/Modals/UserHomeModal";
import UserHistoryModal from "../UserModals/Modals/UserHistoryModal";
import UserSeeNoticeModal from "../UserModals/Modals/UserSeeNoticeModal";
import UserGroupListModal from "../UserModals/Modals/UserGroupListModal";
import UserProfileModal from "../UserModals/Modals/UserProfileModal";
import UserCreateNoticeModal from "../UserModals/Modals/UserCreateNoticeModal";
import UserCreateNewsLetterModal from "../UserModals/Modals/UserCreateNewsLetterModal";
import UserSeeGroupModal from "../UserModals/Modals/UserSeeGroupModal";
import UserReportsModal from "../UserModals/Modals/UserReportsModal";
import UserCreateIndividualReportModal from "../UserModals/Modals/UserCreateIndividualReportModal";
import UserCreateGrupalReportModal from "../UserModals/Modals/UserCreateGrupalReportModal";
import SchoolAdminETModal from "../SchoolAdminModals/Modals/SchoolAdminETModal";
import SchoolAdminSAUModal from "../SchoolAdminModals/Modals/SchoolAdminSAUModal";
import SchoolAdminAllGroupsModal from "../SchoolAdminModals/Modals/SchoolAdminAllGroupsModal";
import SchoolAdminCreateGroupModal from "../SchoolAdminModals/Modals/SchoolAdminCreateGroupModal";
import SchoolAdminCreateStudentModal from "../SchoolAdminModals/Modals/SchoolAdminCreateStudentModal";
import SchoolAdminCreateUserModal from "../SchoolAdminModals/Modals/SchoolAdminCreateUserModal";
import SchoolAdminEditUserModal from "../SchoolAdminModals/Modals/SchoolAdminEditUserModal";
import SchoolAdminEditStudentModal from "../SchoolAdminModals/Modals/SchoolAdminEditStudentModal";
import SchoolCreateModal from "./Modals/SchoolCreateModal";
import SchoolListModal from "./Modals/SchoolListModal";
import { TranslateModalName } from "../../Helpers/TranslateHelpers";
import "./AdminCSS.css"
import '../../index.css'


function AdminModalContainer() {

	const { currentModal, hasLogged, schoolOfUser } = useGeneralContext();
	const { hasSelectedSchool } = useAdminModalContext()
	const navigate = useNavigate()
	const [schoolToEdit, setSchoolToEdit] = useState<SchoolInterface>(DefaultSchool)

	useEffect(() => {
		if (!hasLogged) {
			navigate('/')
		}
	}, [])

	const isNotSuperAdminModal = () => {
		const validModals = ['HomeModal', 'NoticeList', 'GroupList', 'CreateNotice', 'CreateNewsLetter', 'SeeNotice',
			'Profile', 'HomeReport', 'GetIndividualReport', 'GetGrupalReport', 'SeeGroup', 'EmergencyTypes',
			'StudentsAndUsers', 'AllGroupList', 'SeeGroupAdmin', 'CreateGroup', 'CreateUser', 'CreateStudent',
			'EditUser', 'EditStudent']
		return validModals.includes(currentModal)
	}


	return (
		<div className="ModalContainerMainDiv">
			<NavBar />
			<div className="ConteinerModalShower">
				{(hasSelectedSchool && isNotSuperAdminModal()) && (
					<SummarySuperiorSchool
						schoolToSummary={schoolOfUser}
					/>
				)}
				<div className="ModalTitleDiv">
					<h1>{TranslateModalName(currentModal)}</h1>
				</div>
				<div className={`ModalDisplayer ${!(hasSelectedSchool && isNotSuperAdminModal()) && 
					'BiggerModalDisplayer'}`}>
					{
						(() => {
							switch (currentModal) {
								case 'SchoolList':
									return <SchoolListModal
										setSchoolToEdit={setSchoolToEdit}
									/>;
								case 'SchoolCreater':
									return <SchoolCreateModal />;
								case 'SchoolEdit':
									return <EditSchool
										schoolToEdit={schoolToEdit}
									/>;
								case 'HomeModal':
									return <UserHomeModal />
								case 'NoticeList':
									return <UserHistoryModal />
								case 'SeeNotice':
									return <UserSeeNoticeModal />
								case 'GroupList':
									return <UserGroupListModal />
								case 'Profile':
									return <UserProfileModal />
								case 'CreateNotice':
									return <UserCreateNoticeModal />
								case 'CreateNewsLetter':
									return <UserCreateNewsLetterModal />
								case 'SeeGroup':
									return <UserSeeGroupModal />
								case 'HomeReport':
									return <UserReportsModal />
								case 'GetIndividualReport':
									return <UserCreateIndividualReportModal />
								case 'GetGrupalReport':
									return <UserCreateGrupalReportModal />
								case 'EmergencyTypes':
									return <SchoolAdminETModal />
								case 'StudentsAndUsers':
									return <SchoolAdminSAUModal />
								case 'AllGroupList':
									return <SchoolAdminAllGroupsModal />
								case 'CreateGroup':
									return <SchoolAdminCreateGroupModal />
								case 'CreateStudent':
									return <SchoolAdminCreateStudentModal />
								case 'CreateUser':
									return <SchoolAdminCreateUserModal />
								case 'EditUser':
									return <SchoolAdminEditUserModal />
								case 'EditStudent':
									return <SchoolAdminEditStudentModal />
								default:
									return <SchoolListModal
										setSchoolToEdit={setSchoolToEdit}
									/>;
							}
						})()
					}
				</div>
			</div>
		</div>
	)
}

export default AdminModalContainer