import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify'
import reportWebVitals from './reportWebVitals';
import GeneralProvider from './Contexts/GeneralContext/GeneralProvider';
import Routing from './Routing';
import "./index.css"


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const awsconfig = {
	Auth: {
		Cognito: {
			userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
			userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
			region: process.env.REACT_APP_AWS_COGNITO_REGION || ''
		}
	}
};

Amplify.configure(awsconfig)


root.render(
	<React.StrictMode>
		<GeneralProvider>
			<BrowserRouter>
				<div className='AppContainter'>
					<Routing />
				</div>
			</BrowserRouter>
		</GeneralProvider>
	</React.StrictMode>
);

reportWebVitals();
