import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { RoleInterface } from "../../../Interfaces/Entities"
import { ViewSingularTemplateInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import { TranslateClassnameForButton } from "../../../Helpers/TranslateHelpers"
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import EditIcon from '../../../Assets/EditIcon.png'
import ConfirmIcon from '../../../Assets/CheckIcon.png'
import CancelIcon from '../../../Assets/CancelIcon.png'
import '../SchoolAdmin.css'


function ViewSingularTemplate({ templateCode, index, templates, setTemplates }: ViewSingularTemplateInterface) {

	const { schoolOfUser } = useGeneralContext()

	const filteredTemplate = schoolOfUser && schoolOfUser.types	? Object.fromEntries(Object.entries(templates[templateCode])
		.filter(([key, value]) => schoolOfUser.types.includes(key))) : {};
	const [internalTemplate, setInternalTemplate] = useState<RoleInterface>(filteredTemplate)
	const [templateNewName, setTemplateNewName] = useState<string>(templateCode)
	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setTemplateNewName(e.target.value)
	}

	const handlePriorityChange = (type: string, priorityCode: string) => {
		let updatedTemplate: RoleInterface;
		if (priorityCode === 'low') {
			const { [type]: _, ...rest } = internalTemplate;
			updatedTemplate = rest;
		} else {
			updatedTemplate = {
				...internalTemplate,
				[type]: priorityCode
			};
		}
		updateTemplate(updatedTemplate);
	}

	const updateTemplate = (updatedTemplate: RoleInterface) => {
		setInternalTemplate(updatedTemplate);
		const updatedTemplates = {
			...templates,
			[templateCode]: updatedTemplate
		};
		setTemplates(updatedTemplates);
	};

	const handleConfirmEdit = () => {
		if (templateNewName === templateCode) {
			setIsEditing(false);
			return;
		}
		const updatedTemplates: { [key: string]: RoleInterface } = {};
		Object.entries(templates).forEach(([key, value]) => {
			if (key === templateCode) {
				updatedTemplates[templateNewName] = value;
			} else {
				updatedTemplates[key] = value;
			}
		});
		setTemplates(updatedTemplates);
		setIsEditing(false);
	}

	const handleEditCancel = () => {
		setTemplateNewName(templateCode)
		setIsEditing(false)
	}

	const handleDelete = () => {
		const updatedTemplates: { [key: string]: RoleInterface } = {};
		Object.entries(templates).forEach(([key, value]) => {
			if (key !== templateCode) {
				updatedTemplates[key] = value;
			}
		});
		setTemplates(updatedTemplates);
	}


	return (
		<div className='IndividualTemplateDiv'>
			<div className="IndividualTemplateInfoMainDiv">
				<div className="IndividualTemplateInfoContainer">
					<h3 onClick={() => setIsBoxOpen(true)}>{index}</h3>
					<div className="IndividualTemplateInfoRowContainer">
						<div className="IndividualTemplatePriorityFirstRow BlueColorText">
							{isEditing ? (
								<input
									type='text'
									name='newName'
									value={templateNewName}
									onChange={handleNameChange}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}

								/>
							) : (
								<h4>{templateCode}</h4>
							)}
							<button type='button' onClick={() => setIsBoxOpen(!isBoxOpen)}>
								{isBoxOpen ? 'Cerrar' : 'Ver prioridades'}
							</button>
						</div>
						{isBoxOpen && (
							!schoolOfUser ? <></> : schoolOfUser.types.map((type) => {
								if (type !== 'General') {
									const isTypeSelected = !internalTemplate[type] ? 'low' : internalTemplate[type]
									return (
										<div className="IndividualTemplatePriorityRow SuperiorLine">
											<h4 className={TranslateClassnameForButton(type, schoolOfUser)}>{type}</h4>
											<div className="PriorityButtons">
												<button
													type='button'
													className={isTypeSelected === 'low' ?
														'SelectedPrio' : 'UnSelectedPrio'}
													onClick={() => handlePriorityChange(type, 'low')}
												>
													Baja
												</button>
												<button
													type='button'
													className={isTypeSelected === 'mid' ?
														'SelectedPrio' : 'UnSelectedPrio'}
													onClick={() => handlePriorityChange(type, 'mid')}
												>
													Media
												</button>
												<button
													type='button'
													className={isTypeSelected === 'high' ?
														'SelectedPrio' : 'UnSelectedPrio'}
													onClick={() => handlePriorityChange(type, 'high')}
												>
													Alta
												</button>
												<button
													type='button'
													className={isTypeSelected === 'always' ?
														'SelectedPrio' : 'UnSelectedPrio'}
													onClick={() => handlePriorityChange(type, 'always')}
												>
													Prioritaria
												</button>
											</div>
										</div>
									)
								}
							})
						)}
					</div>
				</div>

			</div>
			<button type='button' hidden={!isEditing} onClick={handleConfirmEdit}>
				<img src={ConfirmIcon} alt="ConfirmIcon"/>
			</button>
			<button type='button' hidden={!isEditing} onClick={handleEditCancel}>
				<img src={CancelIcon} alt="CancelIcon"/>
			</button>
			<button type='button' hidden={isEditing} onClick={() => setIsEditing(true)}>
				<img src={EditIcon} alt="EditButton"/>
			</button>
			<button type='button' onClick={handleDelete}>
				<img src={TrashBinIcon} alt="TrashIconButton"/>
			</button>
		</div>
	)
}

export default ViewSingularTemplate