import React, { useRef, useState } from "react"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { GroupInterface, UserInterface } from "../../../Interfaces/Entities"
import SearchGroupAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchGroupAdmin"

import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPostFunction, FetchPutFunction } from "../../../Helpers/FetchHelpers"
import defaultImage from '../../../Assets/BasicUserIcon.png'
import TouchGroupDeletePopUp from "../../../Components/SharedComponents/OtherComponents/TouchGroupDeletePopUp"



function SchoolAdminCreateUserModal() {

	const { schoolUsers, setSchoolUsers } = useSchoolAdminModalContext()
	const { schoolOfUser, setCurrentModal } = useGeneralContext()


	const [userName, setUserName] = useState<string>('')
	const [userLastName, setUserLastName] = useState<string>('')
	const [userEmail, setUserEmail] = useState<string>('')
	const [userPosition, setUserPosition] = useState<string>('')
	const [userGroups, setUserGroups] = useState<GroupInterface[]>([])


	const [newPicture, setNewPicture] = useState<File | undefined>(undefined)
	const [picturePreview, setPicturePreview] = useState<string | undefined>(undefined);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const [isShowingPopUp, setIsShowingPopUp] = useState<boolean>(false)
	const [groupToDeleteInternal, setGroupToDeleteInternal] = useState<GroupInterface | undefined>(undefined)

	const handleImageClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			setNewPicture(file);
			setPicturePreview(objectUrl);
		}
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedName = e.target.value
		setUserName(updatedName)
	}

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedLastName = e.target.value
		setUserLastName(updatedLastName)
	}

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedMail = e.target.value
		setUserEmail(updatedMail)
	}

	const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedPosition = e.target.value
		setUserPosition(updatedPosition)
	}

	const handleSearchClick = (group: GroupInterface) => {
		if (!userGroups.includes(group)) {
			const updatedGroups = [...userGroups, group]
			setUserGroups(updatedGroups)
		}
	}

	const handleDeleteGroupClic = (groupToDelete: GroupInterface) => {
		setGroupToDeleteInternal(groupToDelete)
		setIsShowingPopUp(true)
	}

	const handleGroupDelete = (groupToDelete: GroupInterface) => {
		setIsShowingPopUp(false)
		const updatedGroups = userGroups.filter((group: GroupInterface) => (
			group.SK !== groupToDelete.SK
		))
		setUserGroups(updatedGroups)
	}

	const handleCreateUser = async () => {
		if (!schoolOfUser) {
			return
		}
		try {
			if (userEmail.trim() === '' || !userEmail.includes("@")) {
				alert('Mail no valido')
				return
			}
			if (userName.trim() === '' || userLastName.trim() === '') {
				alert('Nombre o apellido no valido')
				return
			}
			const newUser: UserInterface = {
				SK: userEmail, email: userEmail, name: `${userName} ${userLastName}`
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
			const correctPosition = userPosition.trim() === '' ? ` ` : userPosition

			const body = {
				"school_id": schoolOfUser.SK,
				"attrs": {
					"name": `${userName} ${userLastName}`,
					"email": userEmail,
					"position": correctPosition
				},
			}
			if (newPicture) {
				body['files'] = await PrepareFileToSend(newPicture)
			}
			await FetchPostFunction(url, body)
			alert("Usuario creado exitosamente")
			setCurrentModal('StudentsAndUsers')
			const updatedListOfUsers = [...schoolUsers, newUser]
			setSchoolUsers(updatedListOfUsers)
			setNewUserGroups(newUser.SK)
		} catch (error) {
			alert("Hubo un problema al crear un usuario")
		}
	}

	const setNewUserGroups = async (userSK: string) => {
		if (!schoolOfUser) {
			return
		}
		try {
			for (let index = 0; index < userGroups.length; index++) {
				const currentGroup = userGroups[index];
				const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${userSK}?school_id=${schoolOfUser.SK}`
				const body = {
					"groups": { [currentGroup.SK]: { "General": "mid" } },
					"to_delete": {}
				}
				await FetchPutFunction(url, body)
			}
		} catch (error) { }
	}

	return (
		<div className="CreateUserMainDiv">
			{isShowingPopUp && groupToDeleteInternal && (
				<TouchGroupDeletePopUp 
					groupToDelete={groupToDeleteInternal}
					setIsPopUpVisible={setIsShowingPopUp}
					deleteFunction={handleGroupDelete}
				/>
			)}
			<div onClick={handleImageClick} className="CreateUserImgDiv">
				{newPicture ? (
					<img src={picturePreview} alt="Logo" />
				) : (
					<img src={defaultImage} alt="Logo" />
				)}
				<input
					type="file"
					ref={fileInputRef}
					style={{ display: 'none' }}
					accept="image/*"
					onChange={handleFileChange}
				/>
			</div>
			<div className="CreateUserTwoInternalDivs">
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Nombre</h4>
					<input
						value={userName}
						onChange={handleNameChange}
						placeholder="Ejemplo: Santiago"
					/>
				</div>
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Apellidos</h4>
					<input
						value={userLastName}
						onChange={handleLastNameChange}
						placeholder="Ejemplo: Vicuña Muñoz"
					/>
				</div>
			</div>
			<div className="CreateUserTwoInternalDivs">
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Correo electrónico</h4>
					<input
						value={userEmail}
						onChange={handleEmailChange}
						placeholder="Ejemplo: svicuna@tei.cl"
					/>
				</div>
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Cargo</h4>
					<input
						value={userPosition}
						onChange={handlePositionChange}
						placeholder="Ejemplo: Profesor Jefe"
					/>
				</div>
			</div>
			<div className="CreateUserGroupDiv">
				<h4>Grupos del usuario</h4>
				<SearchGroupAdmin
					currentGroups={userGroups}
					handleSearchClick={handleSearchClick}
				/>
				<div className="CreateUserGroupInternalDiv">
					{userGroups.length === 0 ? (
						<h4>El usuario no tiene grupos</h4>
					) : (
						<div className="CreateUserMapGroupDiv">
							{userGroups.map((group: GroupInterface) => (
								<button onClick={() => handleDeleteGroupClic(group)}>
									{group.name}
								</button>
							))}
						</div>
					)}

				</div>
			</div>
			<div className="CreateUserButtonDiv">
				<button type='button' onClick={handleCreateUser}>
					Crear nuevo usuario
				</button>
			</div>
		</div>
	)
}

export default SchoolAdminCreateUserModal