import { useEffect, useState } from "react";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { ListGroupUsersAdminInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import { GroupInterface, RoleInterface, UserInterface } from "../../../Interfaces/Entities";
import ViewGroupUser from "../ViewData/ViewGroupUser";
import SearchUserAdmin from "../SearchData/SearchUserAdmin";
import { FetchPutFunction } from "../../../Helpers/FetchHelpers";
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import '../SchoolAdmin.css'
import LoadingInfo from "../../SharedComponents/OtherComponents/LoadingInfo";
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider";

function ListGroupUsersAdmin({}: ListGroupUsersAdminInterface) {

	const { schoolOfUser } = useGeneralContext()
	const { getInfoOfSingularGroup } = useSchoolAdminModalContext()
	const { groupToSee } = useUserModalContext()
	const [groupUsers, setGroupUsers] = useState<string[]>([])
	const [userPriorities, setUserPriorities] = useState<{ [userSK: string]: RoleInterface }>({})
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const fetchGroupInfo = async (groupSk: string) => {
		try {
			const updatedGroup = await getInfoOfSingularGroup(groupSk);
			if (!updatedGroup) {
				return
			}
			updateGroupInfo(updatedGroup, groupSk)
		} catch (error) {
			console.log(error)
		}

	};


	const updateGroupInfo = (groupToUpdate: GroupInterface, groupSk: string) => {
		if (groupToUpdate.SK !== groupSk) {
			return
		}
		const updatedUserNames = groupToUpdate.users_priorities ? Object.keys(groupToUpdate.users_priorities) : [];
		const updateUserPriorities = groupToUpdate.users_priorities ? groupToUpdate.users_priorities : {};
		setUserPriorities(updateUserPriorities);
		setGroupUsers(updatedUserNames);
	}

	useEffect(() => {
		setIsLoading(true)
		fetchGroupInfo(groupToSee.SK);
		setIsLoading(false)
	}, [groupToSee]);



	const handleSearchClick = async (user: UserInterface) => {
		if (!schoolOfUser) {
			return
		}
		if (!groupUsers.includes(user.SK)) {
			const updatedGroupUser = [...groupUsers, user.SK]
			setGroupUsers(updatedGroupUser)
			try {
				const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${user.SK}?school_id=${schoolOfUser.SK}`
				const body = {
					"groups": { [groupToSee.SK]: { "General": "mid" } },
					"to_delete": {}
				}
				await FetchPutFunction(url, body)
			} catch (error) { }
		}
	}

	const handleDelete = async (user_id: string) => {
		if (!schoolOfUser) {
			return
		}
		const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${user_id}?school_id=${schoolOfUser.SK}`
		const body = {
			"groups": {},
			"to_delete": { [groupToSee.SK]: {} }
		}
		await FetchPutFunction(url, body)
	}

	const updateTemplate = async (updatedTemplate: RoleInterface, user_id: string) => {
		if (!schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${user_id}?school_id=${schoolOfUser.SK}`
			const body = {
				"groups": { [groupToSee.SK]: updatedTemplate },
				"to_delete": {}
			}
			await FetchPutFunction(url, body)
		} catch (error) { }
	};

	return (
		<div className="ListGroupUsersMainDiv">
			<h4>Usuarios</h4>
			<SearchUserAdmin
				groupUsers={groupUsers}
				handleSearchClick={handleSearchClick}
			/>
			{isLoading ? (
				<LoadingInfo />
			) : (
				groupUsers.length === 0 ? (
					<div className="ListUserEmptyList">
						<h4>Este grupo no tiene usuarios</h4>
					</div>

				) : (
					groupUsers.map((user: string) => (
						<div className="IndividualUserMainDiv">
							<ViewGroupUser
								user={user}
								currentPriorities={userPriorities[user] ? userPriorities[user] : {}}
								updateTemplateExternal={updateTemplate}
							/>
							<button type='button' onClick={() => handleDelete(user)}>
								<img src={TrashBinIcon} alt="DeleteIcon" />
							</button>
						</div>
					))
				)
			)}

		</div>
	)
}

export default ListGroupUsersAdmin