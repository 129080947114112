import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { ViewGroupUserInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import { RoleInterface } from "../../../Interfaces/Entities"
import { TranslateClassnameForButton, TranslateUserName } from "../../../Helpers/TranslateHelpers"
import '../SchoolAdmin.css'


function ViewGroupUser({ user, currentPriorities, updateTemplateExternal }: ViewGroupUserInterface) {

	const { schoolOfUser } = useGeneralContext()
	const filteredTemplate = schoolOfUser && schoolOfUser.types	? Object.fromEntries( 
		Object.entries(currentPriorities).filter(([key, value]) => schoolOfUser.types.includes(key))) : {};
	const [internalTemplate, setInternalTemplate] = useState<RoleInterface>(filteredTemplate)
	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)
	const [selectedTemplate, setSelectedTemplate] = useState<string>('Usar plantilla')


	const handlePriorityChange = (type: string, priorityCode: string) => {
		let updatedTemplate: RoleInterface;
		if (priorityCode === 'low') {
			const { [type]: _, ...rest } = internalTemplate;
			updatedTemplate = rest;
		} else {
			updatedTemplate = {
				...internalTemplate,
				[type]: priorityCode
			};
		}
		updateTemplate(updatedTemplate);
	}

	const updateTemplate = async (updatedTemplate: RoleInterface) => {
		if (updateTemplateExternal) {
			await updateTemplateExternal(updatedTemplate, user)
		}
		setInternalTemplate(updatedTemplate);
	};

	const handleTemplateChange = (event) => {
		if (!schoolOfUser) {
			return
		}
		const templateName = event.target.value
		const allTemplates = Object.keys(schoolOfUser.templates)
		if (allTemplates.includes(templateName)) {
			setSelectedTemplate(templateName)
			updateTemplate(schoolOfUser.templates[templateName])
		}
	}

	return (
		<div className="IndividualUserInternalMainSubDiv">
			<div className={`IndividualUserInternalDiv`}>
				<h3>{TranslateUserName(user)}</h3>
				<button type='button' onClick={() => setIsBoxOpen(!isBoxOpen)}>
					{isBoxOpen ? 'Cerrar' : 'Ver prioridades'}
				</button>
			</div>
			{isBoxOpen && (
				<div className="UserTemplateMainDiv">
					<div className="UserSelectTemplate">
						<select
							id="userTemplate"
							value={selectedTemplate}
							onChange={handleTemplateChange}
							className="SelectTemplateForUser"
						>
							<option value="">Usar plantilla</option>
							{!schoolOfUser ? <></> : Object.keys(schoolOfUser.templates).map((templateName, index) => (
								<option key={index} value={templateName}>
									{templateName}
								</option>
							))}
						</select>
					</div>
					{!schoolOfUser ? <></> :schoolOfUser.types.map((type) => {
						if (type !== 'General') {
							const isTypeSelected = !internalTemplate[type] ? 'low' : internalTemplate[type]
							return (
								<div className="UserTemplatePriorityRow SuperiorLine">
									<h4 className={TranslateClassnameForButton(type, schoolOfUser)}>{type}</h4>
									<div className="PriorityButtonsUser">
										<button
											type='button'
											className={`BasicPrioUserButton ${isTypeSelected === 'low' ?
												'SelectedPrio' : 'UnSelectedPrio'}`}
											onClick={() => handlePriorityChange(type, 'low')}
										>
											Baja
										</button>
										<button
											type='button'
											className={`BasicPrioUserButton ${isTypeSelected === 'mid' ?
												'SelectedPrio' : 'UnSelectedPrio'}`}
											onClick={() => handlePriorityChange(type, 'mid')}
										>
											Media
										</button>
										<button
											type='button'
											className={`BasicPrioUserButton ${isTypeSelected === 'high' ?
												'SelectedPrio' : 'UnSelectedPrio'}`}
											onClick={() => handlePriorityChange(type, 'high')}
										>
											Alta
										</button>
										<button
											type='button'
											className={`BasicPrioUserButton ${isTypeSelected === 'always' ?
												'SelectedPrio' : 'UnSelectedPrio'}`}
											onClick={() => handlePriorityChange(type, 'always')}
										>
											Prioritaria
										</button>
									</div>
								</div>
							)
						}
					})}
				</div>
			)}
		</div>
	)
}

export default ViewGroupUser