import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { confirmSignIn, fetchAuthSession } from "aws-amplify/auth"
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider"
import { ConfirmPasswordInterface } from "../../Interfaces/ComponentsInterfaces/LoginPageInterface"
import { OneSignalInit } from "../../Helpers/OneSignalHelpers"
import LinkClassLogo from '../../Assets/LinkClassLogo.png'
import '../../Views/LoginPage/LoginPage.css'
import { GetRedirectURL, TranslatePasswordError } from "../../Helpers/TranslateHelpers"


function ConfirmPasswordModal({ setLoginModal, loginUserName }: ConfirmPasswordInterface) {

	const { setHasLogged, getInfoOfUser, setUserToken } = useGeneralContext()

	const navigate = useNavigate()

	const [newPassword, setNewPassword] = useState<string>('')
	const [isRevealingPassword, setIsRevealingPassword] = useState<boolean>(false)
	const [userMessage, setUserMessage] = useState<string>('')


	const handleSignedIn = async (username: string) => {
		const session = await fetchAuthSession()
		const userData = await getInfoOfUser(username)
		setUserToken(session.tokens?.accessToken)
		setHasLogged(true)
		await OneSignalInit(username)
		alert("Login exitoso")
		const redirectURL = GetRedirectURL(userData.admin, userData.school)
		navigate(redirectURL)
	}


	const handleConfirmPassword = async () => {
		try {
			await confirmSignIn({ challengeResponse: newPassword })
			handleSignedIn(loginUserName)
		} catch (error: any) {
			const errorMessage = TranslatePasswordError(error.message)
			setUserMessage(errorMessage)
		}
	}

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setNewPassword(e.target.value)
	}

	const handleReturnToLanding = () => {
		navigate('/')
	}


	return (
		<div className="InternalDiv">
			<div className="LoginLogoDiv" onClick={handleReturnToLanding}>
				<img src={LinkClassLogo} alt="LinkClassLogo" />
			</div>
			<h1>Crear contraseña</h1>
			<h3 hidden={userMessage.trim().length === 0}>{userMessage.trim().length > 0 ? userMessage : ""}</h3>

			<div className="LoginPassWordInput">
				<input
					type={isRevealingPassword ? "text" : "password"}
					value={newPassword}
					placeholder="Contraseña"
					onChange={handlePasswordChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<button type="button" onClick={() => setIsRevealingPassword(!isRevealingPassword)}>
					{isRevealingPassword ? "Ocultar Contraseña" : "Mostrar contraseña"}
				</button>
			</div>

			<div className="LostPasswordButtonDiv">
				<button
					type="button"
					onClick={() => setLoginModal('Login')}
				>
					Cancelar
				</button>
				<button
					type='button'
					onClick={handleConfirmPassword}
				>
					Confirmar nueva contraseña
				</button>
			</div>
		</div>
	)
}

export default ConfirmPasswordModal